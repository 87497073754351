<template>
  <div id="profile">
    <pageHeader>
      <cartMenu ref="cartMenu" @parentUpdateCart="updateCart" />
    </pageHeader>

    <div class="breadcrumb mt-4 px-md-15">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon large color="#a53860">mdi-chevron-left</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-container fluid class="px-md-15">
      <v-row>
        <v-col cols="12" sm="4" md="3">
          <div class="profile-card">
            <img
              :src="responseData.main_image"
              alt=""
              srcset=""
              class="profile-img text-center"
            />
            <h2 class="main-color salon-name">{{ responseData.name }}</h2>
            <p
              class="second-color"
              v-if="
                responseData.categories && responseData.categories.length > 0
              "
            >
              <span
                v-for="(category, index) in responseData.categories"
                :key="index"
              >
                {{ category.name }}
                <span
                  v-if="
                    responseData.categories &&
                    index < responseData.categories.length - 1
                  "
                >
                  -
                </span>
              </span>
            </p>
            <!-- <div class="rating">
              <v-icon color="#dea22c">mdi-hexagram</v-icon>
              <v-icon color="#dea22c">mdi-hexagram</v-icon>
              <v-icon color="#dea22c">mdi-hexagram</v-icon>
              <v-icon>mdi-hexagram-outline</v-icon>
              <v-icon>mdi-hexagram-outline</v-icon>
            </div> -->
            <p class="open mb-0" v-if="responseData.salon_open">
              <v-icon color="#63cd72" class="ml-2" small
                >mdi-checkbox-blank-circle</v-icon
              >
              <span>مفتوح</span>
            </p>
            <p class="close red--text mb-0" v-else>
              <v-icon color="#E74C3C" class="ml-2" small
                >mdi-checkbox-blank-circle</v-icon
              >
              <span>مغلق</span>
            </p>
            <p class="open-hours mb-0" v-if="responseData.salon_open">
              <v-icon color="#46101f">mdi-clock-outline</v-icon>
              <span class="grey--text">{{
                responseData.today_working_hours
              }}</span>
            </p>
            <p
              v-else
              class="second-color showAvailabel mr-5"
              @click="openInfoTab('working-hours')"
            >
              ساعات العمل
              <v-icon color="#a53860">mdi-arrow-left</v-icon>
            </p>
            <p
              class="location mb-0"
              v-if="responseData.branches && responseData.branches.length > 0"
            >
              <v-icon color="#46101f" class="ml-2">mdi-map-marker</v-icon>
              <span>{{ responseData.branches[0].name }} </span>
            </p>
            <v-chip
              color="#a53860"
              class="white--text others mx-2 my-2"
              v-if="responseData.branches && responseData.branches.length > 1"
              @click="openInfoTab('branches')"
              >+{{ responseData.branches.length - 1 }} اخرى</v-chip
            >

            <div
              class="socials my-4"
              v-if="
                responseData.can_show_social_media &&
                (socials.facebook ||
                  socials.instagram ||
                  socials.snapchat ||
                  socials.twitter)
              "
            >
              <ul class="unstyled-list">
                <li v-if="socials && socials.facebook">
                  <a target="_blank" :href="socials.facebook">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li v-if="socials && socials.instagram">
                  <a target="_blank" :href="socials.instagram">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li v-if="socials && socials.snapchat">
                  <a target="_blank" :href="socials.snapchat">
                    <i class="fab fa-snapchat-ghost"></i>
                  </a>
                </li>
                <li v-if="socials && socials.twitter">
                  <a target="_blank" :href="socials.twitter">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="8">
          <v-tabs fixed-tabs v-model="tab">
            <v-tab>الجلسات</v-tab>

            <v-tab>الصور</v-tab>
            <v-tab>التفاصيل</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card class="sessions-card" flat>
                <v-card-text>
                  <v-chip-group
                    v-model="services"
                    class="services"
                    column
                    multiple
                  >
                    <v-chip
                      v-for="service in responseData.services"
                      :key="service.id"
                      :value="service.id"
                      outlined
                    >
                      {{ service.name }}
                    </v-chip>
                  </v-chip-group>

                  <div
                    class="service mt-4"
                    v-for="(session, index) in responseData.sessions"
                    :key="index"
                  >
                    <v-row>
                      <v-col cols="12" sm="4" md="6" class="py-0 py-sm-3">
                        <h3 class="service-title main-black mb-4">
                          <span> {{ session.name }}</span>
                        </h3>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="8"
                        md="6"
                        class="text-left icons py-0 py-sm-3"
                      >
                        <div
                          class="d-flex align-baseline"
                          :class="
                            session.available_at_home
                              ? 'justify-space-between'
                              : 'justify-end'
                          "
                        >
                          <p
                            class="main-black home-service"
                            v-if="session.available_at_home"
                          >
                            الخدمة متاحه بالمنزل
                          </p>
                          <div class="icons">
                            <span class="text-center" v-if="show_cart">
                              <v-btn
                                @click="addToCart(session.id)"
                                color="#46101f"
                                v-if="
                                  !cartItemsIds.includes(session.id) &&
                                  userType == 'client'
                                "
                                min-width="30px"
                                small
                              >
                                <v-icon color="#ffffff" small>mdi-cart</v-icon>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <div class="des grey--text">
                      <p class="pric second-color">{{ session.price }} ر.س</p>
                    </div>
                    <hr class="hr" />
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item class="other_pictures">
              <v-card flat>
                <v-card-text class="mt-4">
                  <v-row
                    v-if="
                      responseData.other_pictures &&
                      responseData.other_pictures.length > 0
                    "
                  >
                    <v-col
                      v-for="(pic, index) in responseData.other_pictures"
                      :key="index"
                      cols="6"
                      sm="4"
                      class="px-1 py-0"
                    >
                      <img :src="pic.path" class="other_image" />
                    </v-col>
                  </v-row>
                  <div class="text-center" v-else>
                    <p class="mt-12">لا يوجد صور أخرى</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="info-tab">
                <v-card-text>
                  <div
                    v-if="
                      responseData.services && responseData.services.length > 0
                    "
                  >
                    <h3 class="info-title main-black mb-2">الخدمات</h3>
                    <p class="desc">
                      <v-chip
                        class="mx-2 white--text mb-2"
                        color="#bea9b1"
                        v-for="(service, index) in responseData.services"
                        :key="index"
                      >
                        {{ service.name }}
                      </v-chip>
                    </p>
                    <hr class="hr my-5" />
                  </div>

                  <div
                    class="branches"
                    id="branches"
                    v-if="
                      responseData.branches && responseData.branches.length > 0
                    "
                  >
                    <h3 class="info-title main-black mb-2">الفروع</h3>
                    <v-row
                      class="branch"
                      v-for="(branch, index) in responseData.branches"
                      :key="index"
                    >
                      <v-col cols="10" sm="11">
                        <p>
                          <span class="second-color font-bold"
                            >{{ branch.name }} -
                          </span>
                          <!-- <span class="">{{ branch.name }} </span> -->
                          <span>{{ branch.formatted_address }}</span>
                        </p>
                      </v-col>
                      <v-col cols="2" sm="1">
                        <v-btn icon :to="`/branch?id=${branch.id}`">
                          <v-icon color="#46101f">mdi-map-marker</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <hr class="hr my-5" />

                  <div
                    class="working-hours"
                    id="working-hours"
                    v-if="
                      responseData.working_hours &&
                      responseData.working_hours.length > 0
                    "
                  >
                    <h3 class="info-title main-black mb-2">ساعات العمل</h3>
                    <v-row
                      v-for="(
                        working_hour, index
                      ) in responseData.working_hours"
                      :key="index"
                    >
                      <v-col cols="4" sm="3">
                        <p class="mb-0">
                          <strong>{{ working_hour.day }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="8" sm="9">
                        <p class="mb-0">
                          {{ working_hour.work_time }}
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="12">
          <staticCartMenu
            v-if="show_cart"
            :cartItems="cartItems"
            :totalPrice="totalPrice"
            @deleteCartItem="deleteCartItem"
          >
            <v-btn class="login-btn" large @click="confirmReservation"
              >تاكيد الحجز

              <v-icon color="#a53860" class="cart-btn-icon"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
          </staticCartMenu>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      persistent
      width="40%"
      style="boder-radius: 16px"
    >
      <v-card id="cart-dialog">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-card-text class="text-center pa-5 pa-sm-10">
          <p class="message" v-if="cartItems && cartItems.length > 0">
            سلة التسوق الخاصة بك بها جلسات من {{ cartItems[0].salon }} هل تريد
            استبدالها بجلسات من {{ responseData.name }}؟ إذا قمت بالضغط على
            "نعم" ، فسيتم إفراغ سلة التسوق الخاصة بك.
          </p>
        </v-card-text>
        <v-card-actions class="justify-end pb-4">
          <v-btn
            class="login-btn white--text"
            color="#a53860"
            @click="DeleteAllCart"
            >نعم</v-btn
          >
          <v-btn class="second-btn" @click="dialog = false">إلغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import cartMenu from "@/components/cartMenu.vue";
import staticCartMenu from "@/components/staticCartMenu.vue";
import pageHeader from "@/components/pageHeader.vue";

export default {
  name: "SalonProfile",
  components: { cartMenu, pageHeader, staticCartMenu },

  data() {
    return {
      dialog: false,
      tab: 0,
      services: [],
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },

        {
          text: "",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],
      loggedIn: localStorage.getItem("loggedIn"),
      userType: localStorage.getItem("type"),
      responseData: [],
      cartItems: [],
      cartItemsIds: [],
      cartId: "",
      totalPrice: "",
      socials: {},
      show_cart: false,
    };
  },
  watch: {
    services: {
      handler() {
        this.getFilteredSessions();
      },
    },
  },
  methods: {
    updateCart(cartId, cartItems, totalPrice, cartItemsIds) {
      console.log("updated");
      this.cartId = cartId;
      this.cartItems = cartItems;
      this.totalPrice = totalPrice;
      this.cartItemsIds = cartItemsIds;
    },
    openInfoTab(secParam) {
      this.tab = 2;
      setTimeout(() => {
        document
          .getElementById(secParam)
          .scrollIntoView({ behavior: "smooth", block: "center" });
      }, 500);
    },
    getSalonInfo() {
      let salonId = this.$route.params.id;
      this.$http
        .get(this.base_url + "/front/salonProfile/" + salonId, {})
        .then((response) => {
          this.responseData = response.data.data;
          this.show_cart = response.data.data.show_cart;
          this.socials.facebook = response.data.data.facebook_link;
          this.socials.instagram = response.data.data.instagram_link;
          this.socials.twitter = response.data.data.twitter_link;
          this.socials.snapchat = response.data.data.snapchat_link;

          this.items[this.items.length - 1].text = this.responseData.name;
        });
    },
    getFilteredSessions() {
      let salonId = this.$route.params.id;

      this.$http
        .get(
          this.base_url +
            "/front/getsessionsByFilter?salon_id=" +
            salonId +
            "&services=" +
            this.services,
          {}
        )
        .then((response) => {
          this.responseData.sessions = response.data.data;
        });
    },
    addToCart(selectedSession) {
      let headers = {};
      if (localStorage.loggedIn == "true") {
        headers = {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        };
      } else {
        headers = {};
      }
      if (this.checkExistsInCart()) {
        this.$http
          .get(
            this.base_url +
              "/front/addToCart/" +
              selectedSession +
              "?cartID=" +
              this.cartId,
            headers
          )
          .then((response) => {
            this.cartId = response.data.data.cartID;
            localStorage.setItem("cartId", this.cartId);
            this.cartItems = response.data.data.items;
            this.totalPrice = response.data.data.totalPrice;
            console.log(this.cartItems);
            this.cartItemsIds = [];
            this.cartItems.forEach((item) => {
              this.cartItemsIds.push(item.id);
            });
            this.$refs.cartMenu.updateCart(
              this.cartId,
              this.cartItems,
              this.totalPrice,
              this.cartItemsIds
            );
          });
      } else {
        this.dialog = true;
      }
    },
    checkExistsInCart() {
      let check = false;
      if (this.cartItems.length > 0) {
        this.cartItems.forEach((item) => {
          if (this.responseData.id == item.salon_id) {
            check = true;
          }
        });
      } else {
        return true;
      }
      return check;
    },
    showCart() {
      if (localStorage.getItem("cartId")) {
        this.cartId = localStorage.getItem("cartId");
      }
      if (this.cartId) {
        this.$http
          .get(this.base_url + "/front/showCart?cartID=" + this.cartId, {})
          .then((response) => {
            console.log(response);
            this.cartId = response.data.data.cartID;
            this.cartItems = response.data.data.items;
            this.totalPrice = response.data.data.totalPrice;
            this.cartItemsIds = [];

            this.cartItems.forEach((item) => {
              this.cartItemsIds.push(item.id);
            });
          });
      }
    },
    deleteCartItem(itemId) {
      this.$http
        .get(
          this.base_url +
            "/front/removeFromCart/" +
            itemId +
            "?cartID=" +
            this.cartId,
          {}
        )
        .then((response) => {
          this.cartItems = response.data.data.items;
          this.totalPrice = response.data.data.totalPrice;
          this.cartItemsIds = [];

          this.cartItems.forEach((item) => {
            this.cartItemsIds.push(item.id);
          });

          this.$refs.cartMenu.updateCart(
            this.cartId,
            this.cartItems,
            this.totalPrice,
            this.cartItemsIds
          );
        });
    },
    DeleteAllCart() {
      this.$http
        .get(this.base_url + "/front/removeAllItems?cartID=" + this.cartId, {})
        .then((response) => {
          if (response.data.status.error == false) {
            this.cartItems = [];
            this.cartItemsIds = [];
            this.dialog = false;
            this.totalPrice = "";
            this.$refs.cartMenu.updateCart(
              this.cartId,
              this.cartItems,
              this.totalPrice,
              this.cartItemsIds
            );
          }
        });
    },
    confirmReservation() {
      if (this.loggedIn == "true") {
        this.$router.push("/reserve/steps?step=1");
      } else {
        this.$router.push("/login");
      }
    },
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  mounted() {
    this.getSalonInfo();
    this.showCart();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}
.header {
  background-image: url("../assets/bg.jpg");
  border-radius: 0 0 0 40px;
  background-size: cover;
  height: 300px !important;
  position: relative;
  padding-right: 50px;
  padding-left: 50px;
  .nav-actions {
    margin-top: 6rem;
  }
  .main-head {
    margin-top: 2rem !important;
  }
  .search-sec {
    max-width: 80%;
    text-align: center;
    margin: 0 auto !important;
    margin-top: 4rem !important;
  }
}

.profile-card {
  border-left: 2px solid #dddddd;
  height: 100% !important;
  .salon-name {
    font-size: 2rem;
    font-weight: bold;
  }
  .profile-img {
    min-width: 90%;
    max-width: 90%;
    margin: 0 auto;
    max-height: 250px;
    object-fit: fill;
    // width: 250px;
    border-radius: 0 0 0 30px;
  }
  .showAvailabel {
    cursor: pointer;
  }
  .others {
    cursor: pointer;
  }
  .open {
    color: #63cd72;
  }

  .socials {
    .unstyled-list {
      li {
        display: inline-block;
        margin: 0 5px;
        width: 30px;
        height: 30px;
        background-color: $second-color;
        margin: 5px;
        border-radius: 50%;
        text-align: center;
        padding-top: 1px;
        a {
          color: #ffffff;
          padding-top: 3px !important;
          display: inline-block;
        }
      }
    }
  }
}
.sessions-card {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 50vh;
  .service {
    .service-title {
      font-size: 1.25rem;
    }
    .home-service {
      border: 1px solid #707070;
      padding: 5px 10px;
      border-radius: 12px;
    }
    .pric {
      font-size: 1rem;
    }
    .icons {
    }
  }
}

.other_pictures {
  .other_image {
    object-fit: fill;
    height: 200px;
    width: 100%;
    border-radius: 0 30px 0 0;
  }
}

#cart-dialog {
  border-radius: 16px !important;
}
#cart-dialog .v-btn {
  margin: 12px !important;
  color: #b9b9b9 !important;
  font-size: 25px !important;
}

@media (max-width: 600px) {
  .header {
    padding-right: 12px;
    padding-left: 12px;
    .logo {
      width: 150px !important;
    }
    .nav-actions {
      margin-top: 0;

      .menu-img {
        width: 32px;
      }
    }
  }
}

@media (max-width: 960px) and (min-width: 600px) {
}
</style>
